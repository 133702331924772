import ComingSoonTile from "./ConnectionTiles/ComingSoonTile";
import ModalPromptTile from "./ConnectionTiles/ModalPromptTile";
import ExternalDocTile from "./ConnectionTiles/ExternalDocTile";
import ExternalDownloadTile from "./ConnectionTiles/ExternalDownloadTile";
import { ExternalSubMenuDownloadTile } from "./ConnectionTiles/ExternalSubMenuDownloadTile";
import DocOnlyTile from "./ConnectionTiles/DocOnlyTile";
import UpgradePlanTile from "./ConnectionTiles/UpgradePlanTile";
import { IWorkspaceListItem } from "../../models";
import { IClientDetails } from "./ClientDetails";
import { ClientTileType } from "./ClientTileType";
import OpenAPIModalPromptTitle from "./ConnectionTiles/OpenAPIModalPromptTitle";
import IconSprite from "./IconSprite";

interface ClientCardProps {
  clientDetails: IClientDetails;
  workspaces: IWorkspaceListItem[];
}

export const ClientCard = ({ clientDetails, workspaces }: ClientCardProps) => {
  let tile;

  switch (clientDetails.type) {
    case ClientTileType.ComingSoonTile:
      tile = (
        <ComingSoonTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          testId={clientDetails.testId}
        />
      );
      break;

    case ClientTileType.UpgradePlanTile:
      tile = (
        <UpgradePlanTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
        />
      );
      break;

    case ClientTileType.ModalPromptTile:
      tile = (
        <ModalPromptTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          externalDoc={clientDetails.docsURL!}
          modalTitle={clientDetails.modalTitle!}
          modalBodyValues={clientDetails.modalBodyValues!}
          testId={clientDetails.testId}
          workspaces={workspaces}
          isOdata={clientDetails.ODATA!}
          isOpenApi={clientDetails.OpenAPI!}
          isRestApi={clientDetails.REST}
        />
      );
      break;

    case ClientTileType.OpenAPIModalPromptTitle:
      tile = (
        <OpenAPIModalPromptTitle
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          externalDoc={clientDetails.docsURL!}
          modalTitle={clientDetails.modalTitle!}
          modalBodyValues={clientDetails.modalBodyValues!}
          testId={clientDetails.testId}
          workspaces={workspaces}
          isOdata={clientDetails.ODATA!}
          isOpenApi={clientDetails.OpenAPI!}
          isRestApi={clientDetails.REST}
        />
      );
      break;

    case ClientTileType.ExternalDocTile:
      tile = (
        <ExternalDocTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          internalDoc={clientDetails.docsURL!}
          externalDoc={clientDetails.externalURL!}
          testId={clientDetails.testId}
        />
      );
      break;

    case ClientTileType.ExternalDownloadTile:
      tile = (
        <ExternalDownloadTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          internalDoc={clientDetails.docsURL!}
          externalDoc={clientDetails.externalURL!}
          testId={clientDetails.testId}
        />
      );
      break;

    case ClientTileType.DocOnlyTile:
      tile = (
        <DocOnlyTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          internalDoc={clientDetails.docsURL!}
          testId={clientDetails.testId}
        />
      );
      break;

    case ClientTileType.ExternalSubMenuDownloadTile:
      tile = (
        <ExternalSubMenuDownloadTile
          idName={clientDetails.idName}
          imageIcon={clientDetails.imageIcon}
          description={clientDetails.description}
          showBetaBadge={clientDetails.beta ?? false}
          internalDoc={clientDetails.docsURL!}
          downloadUrls={clientDetails.downloadUrls!}
          testId={clientDetails.testId}
        />
      );
      break;

    default:
      tile = <></>;
      break;
  }

  return (
    <>
      <IconSprite />
      {tile}
    </>
  );
};

export default ClientCard;
